import { Box, Typography } from "@mui/material";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import _ from "lodash";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./css/Fileponde.css";
import Form from "./form/Form";
import { useEffect, useState } from "react";

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize);

const App = () => {
  const [isLandscape, setIsLandscape] = useState(false);

  function isBrowserInLandscape() {
    const orientation = window.orientation;
    const isLandscape = Math.abs(orientation) === 90;

    return isLandscape;
  }

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(isBrowserInLandscape());
    };

    // Initial check
    handleOrientationChange();

    window.addEventListener("orientationchange", handleOrientationChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <div className="w-full lg:h-[100vh] h-[100vh] flex items-center justify-center">
      <div
        className={`sm:w-1/2 lg:w-1/3 w-[90%] ${
          isLandscape && "lg:h-[80%]  md:h-[80%] sm:h-[90%] h-full"
        }  bg-white rounded-lg overflow-auto py-3`}
      >
        <Box className={"w-full flex flex-col items-center justify-center"}>
          <img className="mt-10 mb-10" width={100} src="https://ilseandrewedding.com/wp-content/uploads/2023/06/IA-930x1024.png" alt="S" />

          <Typography variant="h4">Ilse&Andre</Typography>
          <Typography variant="h4">Wedding</Typography>
        </Box>

        <Box className={"w-full py-2 pb-2"}>
          <Form />
          <div class="text-center px-10 mt-10 text-gray-800	">Podrás ver las fotos en una galería, pero primero envía la tuya</div>
        </Box>
        
      </div>

    </div>
  );
};

export default App;
